$color-main: #1D3592;
$color-accent: #00B862;
$color-black: #000000;
$color-white: #f8f8f8;
$plyr-color-main: $color-main;
$plyr-range-thumb-height: 26px !default;
$plyr-range-track-height: 12px !default;
$plyr-control-icon-size: 24px;
$plyr-video-control-color: darken($color-main, 15);
$plyr-video-control-background: darken($color-main, 15);
$plyr-range-thumb-background: $color-main;
$plyr-video-control-background-hover: $color-main;
