// Base
@import "variables.scss";
@import "map.scss";
@import "~plyr/src/sass/plyr";

//Variables

@font-face {
  font-family: 'Bold';
  src: url('../fonts/Circe-Bold.eot');
  src: url('../fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circe-Bold.woff2') format('woff2'),
    url('../fonts/Circe-Bold.woff') format('woff'),
    url('../fonts/Circe-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Regular';
  src: url('../fonts/Circe-Regular.eot');
  src: url('../fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circe-Regular.woff2') format('woff2'),
    url('../fonts/Circe-Regular.woff') format('woff'),
    url('../fonts/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-regular: "Regular", Helvetica, Arial, sans-serif;
$font-bold: "Bold", Helvetica, Arial, sans-serif;

$height-large-height: 550px;

.plyr video,
.plyr audio,
.plyr iframe {
  max-height: 650px;
}

a {
  text-decoration: none;
}

:focus {
  outline: none !important;
}

.font-regular {
  font-family: $font-regular;
}

.font-bold,
strong,
b {
  font-family: $font-bold;
}

.border-circle {
  border-radius: 100%;
  overflow: hidden;
}

body {
  font-family: $font-regular;
  margin: 0;


  .swiper-thumbs {
    &.title-only {
      .swiper-wrapper {
        align-items: center;
      }
    }

    .swiper-slide {
      border: 2px solid darken($color-white, 5%);
      border-radius: 4px;
    }

    .swiper-slide-thumb-active {
      border: 2px solid $color-accent;
      border-radius: 4px;
    }
  }

  .gallery__next {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -25px;
    z-index: 10;
  }

  .gallery__prev {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -25px;
    z-index: 10;
  }

  .pointer {
    cursor: pointer;
  }

  .app-content {
    a {
      text-decoration: none;
      color: $color-accent;
    }
  }

  .app-player-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }

  h1,
  h2,
  h3,
  h4 {
    word-break: break-word;
  }
}

.uppercase {
  text-transform: uppercase;
}

.app-headline {
  position: relative;

  a {
    text-decoration: none;
    color: $color-accent;
  }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border-radius: 4px;
  border: 4px solid $color-accent;
  cursor: pointer;
}

.app-nav-link {
  font-size: 21px;
  text-decoration: uppercase;
  color: currentColor;
  margin: 8px;

  &.active {
    border-bottom: 2px solid currentColor;
  }
}


.plyr--full-ui input[type=range] {
  color: $plyr-color-main;
}

.plyr--video .plyr__controls {
  background: lighten($color-black, 5);
  padding-top: 12px;
}

.plyr--audio .plyr__controls {
  background: lighten($color-black, 5);
  padding-top: 12px;
}

.plyr__video-wrapper,
.plyr--video,
.plyr--audio {
  background: $color-black;
}

.plyr__controls .plyr__controls__item.plyr__time {
  color: darken($plyr-color-main, 15);
  font-family: $font-bold;
  font-size: 18px;
}

.image-gallery-icon {
  outline: none;

  &:focus {
    outline: none;
  }
}

.image-gallery-slide .image-gallery-description {
  bottom: 0;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  padding: 40px;

  svg {
    width: 90px;
    height: 90px;
    left: 9px;
    color: $color-accent;
  }
}

.app-audio {
  video {
    display: none;
  }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-bottom-left {
  display: none;
}

// .mapboxgl-canvas-container {
//   position: relative;
//   .map-marker {
//     position: absolute;
//   }
// }
