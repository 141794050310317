.map-overlay fieldset {
  display: none;
  background: #ddd;
  border: none;
  padding: 10px;
  margin: 0;
}
.map-overlay input {
  display: block;
  border: none;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
}
.map-overlay .listing {
  overflow: auto;
  max-height: 100%;
}
.map-overlay .listing > * {
  display: block;
  padding: 5px 10px;
  margin: 0;
}
.map-overlay .listing a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #404;
  text-decoration: none;
}
.map-overlay .listing a:last-child {
  border: none;
}
.map-overlay .listing a:hover {
  background: #f0f0f0;
}
